.small-modal__backdrop {
  z-index: 102;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.small-modal__wrapper {
  cursor: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);

  @media (max-width: 800px) {
    padding: 0;
  }
}

.small-modal {
  z-index: 103;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: $white;
  width: 100%;
  max-width: 750px;
  padding: 60px;
  padding-top: 40px;
  height: auto;
  max-height: 90vh;
  overflow: auto;
  overflow-x: hidden;

  .heading-2-5 {
    padding: 0 90px;
    margin-bottom: 0;
  }

  .heading-2-5 ~ .paragraph {
    padding: 0 90px;
    margin-top: 26px;
    margin-bottom: 0;

    &.small-margin {
      margin-top: 5px;
    }
  }

  .pill.width-full {
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    max-height: 100vh;
    min-height: 100vh;
    padding: 15px;
    max-width: none;

    .heading-2-5 {
      font-size: 24px;
      line-height: 1.4;
      text-transform: uppercase;
    }

    .modal__header-close {
      height: 50px;

      .button__in {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0;
        top: 0;
        left: 0;
      }
    }
  }
}

.small-modal__header {
  margin-bottom: 30px;

  &.small-modal__header--without-margin {
    margin-bottom: 0;
  }
}

.small-modal--plain {
  padding: 0 !important;
}

.small-modal--wide .small-modal {
  max-width: 950px;
}

.small-modal ul {
  margin: 0;
}

.small-modal li {
  margin: 0;
}

.small-modal .margin-top {
  @media (max-width: $mobileBreakpoint) {
    margin-top: 30px;
  }
}

.small-modal--desktop-only {
  @media (max-width: 929px) {
    display: none;
  }
}

.small-modal__button {
  margin-top: 32px;
}

.small-modal--addon {
  .input-wrapper {
    padding: 8px 15px;

    .styled-checkbox--multiline + label::before {
      top: 6px;
    }

    .styled-checkbox:checked + label::after {
      top: 21px;
    }

    label {
      line-height: 25px;
    }
  }

  hr {
    margin: 8px 0;
  }
}
